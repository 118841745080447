import PropTypes from "prop-types";
import React from "react";

import Image from "../components/image";

import styled from "styled-components";
const Description = styled.div`
    p {
        margin-bottom: 1rem;
    }
`;

function Feature({
    contentPosition,
    backgroundPosition,
    backgroundSize,
    img,
    label,
    title,
    description,
    padding,
    fullsize,
    bgClass,
    titleColorClass,
    textColorClass,
}) {
    const style = {
        // backgroundImage: `url(${img})`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: backgroundSize,
        backgroundPosition: backgroundPosition,
    };

    const content = (
        <div
            className={`${bgClass} w-full md:w-1/2 p-8 bg-opacity-90 rounded-xl shadow-xl`}
        >
            <span
                className={`uppercase tracking-widest text-xs ${titleColorClass}`}
            >
                {label}
            </span>
            <h2
                className={`${titleColorClass} text-2xl md:text-3xl leading-tight mb-6 mt-2 font-display`}
            >
                {title}
            </h2>
            <Description className={`${textColorClass} text-xl`}>
                {description}
            </Description>
        </div>
    );

    return (
        <section className="py-10" style={fullsize ? style : null}>
            <Image
                isBackground={true}
                filename={img}
                content={content}
                className={`${
                    contentPosition === "left"
                        ? `md:flex-row`
                        : `md:flex-row-reverse`
                } flex flex-col-reverse items-center w-full max-w-6xl mx-auto ${padding}`}
                style={!fullsize ? style : null}
            />
        </section>
    );
}

Feature.defaultProps = {
    contentPosition: `left`,
    backgroundSize: `100% auto`,
    backgroundPosition: `top right`,
    padding: `py-8 px-4 md:px-20 md:py-10`,
    fullsize: true,
    bgClass: `bg-white`,
    titleColorClass: ``,
    textColorClass: `text-gray-600`,
};

Feature.propTypes = {
    contentPosition: PropTypes.string,
    backgroundPosition: PropTypes.string,
    backgroundSize: PropTypes.string,
    img: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    padding: PropTypes.string,
    fullsize: PropTypes.bool,
    bgClass: PropTypes.string,
    titleColorClass: PropTypes.string,
    textColorClass: PropTypes.string,
};

export default Feature;
