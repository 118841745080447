import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SEOAdditional from "../components/seo-additional";
import Cta from "../components/cta";
import Hero from "../components/hero";
import FeatureList from "../components/feature-list";
import FeatureBg from "../components/feature-bg";
import parse from "html-react-parser";

import ReactTooltip from "react-tooltip";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { UilAngleDown } from "@iconscout/react-unicons";

import styled from "styled-components";
const Info = styled.div`
    a {
        text-decoration: underline;
    }
    b {
        font-weight: bold !important;
    }
`;

function Icon({ icon }) {
    let uil = {
        chevronDown: UilAngleDown
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color="#718096" />;
}

function MarketplaceIntegration({ data }) {
    let d = data.contentfulPageMarketplaceIntegration;
    const [itemsToShow, setItemsToShow] = useState(1);

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />
            <Hero
                props={{
                    label: d.label,
                    title: d.title,
                    desc: renderRichText(d.subtitle),
                }}
            />

            {!!d.info &&
            <div className="w-full max-w-4xl mx-auto px-4 py-8 md:px-8 md:py-8 ">
                <Info
                    className="text-gray-800 rounded-lg p-4 relative shadow-lg"
                    style={{ background: `#fadb8a` }}
                >
                    <div className="absolute -left-2 -top-2">
                        <img className="w-16 h-16" src="../icons/gift.png" />
                    </div>
                    <div className="pl-14">{renderRichText(d.info)}</div>
                </Info>
            </div>
            }

            <FeatureBg
                contentPosition="left"
                backgroundPosition="center right"
                backgroundSize="40rem"
                img={`${d.featureBg.img}.png`}
                label={d.featureBg.label}
                title={d.featureBg.title}
                description={parse(d.featureBg.description)}
                padding="py-8 px-4 md:px-10 md:py-10"
                fullsize={false}
                bgClass="bg-green-400"
                textColorClass="text-green-800"
                titleColorClass="text-gray-800"
            />

            <section style={{ backgroundColor: `` }} className="py-10">
                <div className="w-full max-w-6xl mx-auto px-4 py-8 md:px-8 md:py-8">
                    <h2 className="text-xl mb-6 md:mb-10 text-center leading-tight font-display max-w-xl mx-auto">
                        {d.logo.title}
                    </h2>

                    <div className="flex flex-wrap justify-around items-center">
                        {d.logo.content.map((item) => (
                            <div
                                key={item.name}
                                className="w-44 h-12 flex items-center justify-center relative"
                                data-tip={
                                    item.status == "coming soon"
                                        ? item.status
                                        : null
                                }
                            >
                                <ReactTooltip />

                                {item.status == "coming soon" ? (
                                    <div className="w-full h-full bg-white absolute top-0 left-0 bg-opacity-50 dark:bg-opacity-0"></div>
                                ) : null}

                                <img
                                    src={`../icons/brands/${item.img}`}
                                    className="content-center"
                                    style={{
                                        width: `auto`,
                                        height: `auto`,
                                        maxWidth: `150px`,
                                        maxHeight: `50px`,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="max-w-6xl mx-auto py-10">
                <div className="flex flex-wrap w-full">
                    {d.features.map((item) => (
                        <FeatureList key={item.title} props={item} />
                    ))}
                </div>
            </section>

            <Cta />

            {d.seoAdditional.length ?
                <section className="px-4 bg-gray-50 dark:bg-gray-800 space-y-5">
                    {d.seoAdditional.map((seoAdd, key) => (
                        <SEOAdditional
                            key={`seo-additional-${key}`}
                            titleTag={seoAdd.titleTag}
                            title={seoAdd.title}
                            content={seoAdd.content}
                            className={key >= itemsToShow ? 'hidden' : ''}
                        />
                    ))}

                    {itemsToShow == 1 ?
                        <div className="w-full max-w-6xl mx-auto">
                            <button
                                type="button"
                                className="flex flex-row text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 hover:underline border-b border-dashed"
                                onClick={() => setItemsToShow(d.seoAdditional.length)}
                            >
                                <span>Selengkapnya</span><Icon icon={'chevronDown'} />
                            </button>
                        </div>
                        : ''
                    }
                </section>
                : ''
            }
        </Layout>
    );
}

Icon.propTypes = {
    icon: PropTypes.string,
};

MarketplaceIntegration.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageMarketplaceIntegration {
            seo {
                title
                description
                keywords
            }
            seoAdditional {
                titleTag
                title
                content
            }
            label
            title
            subtitle {
                raw
            }
            features {
                title
                icon
                description
            }
            featureBg {
                label
                img
                title
                description
            }
            logo {
                title
                content {
                    name
                    img
                    status
                }
            }
        }
    }
`;

export default MarketplaceIntegration;
